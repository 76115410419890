import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroText from "../components/heroText"
import SignUpForm from "../components/forms/signUpForm"
import TextGroup from "../components/ui/textGroup"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-gtag"

const Anchor = styled(OutboundLink)`
  color: #222222;

  :hover{
    color: #000000;
  }
`;

const SignUpPage = () => (
  <Layout>
    <SEO title="Sign Up" />
    <HeroText
      statement="Sign Up"
      caption="Ready to get started and take a look around?"
    ></HeroText>

    <TextGroup
      heading="Register Now"
      text={<><p>
        The sign up process is painless and someone from our sales team will contact you shortly to find out if it is the right fit for you.
      </p>
        <p>
          <Anchor href="https://app.beyondattendance.com/#!/account/signin" rel="noreferrer">I already have an account</Anchor>
        </p></>}>
      <SignUpForm />
    </TextGroup>

  </Layout>
)

export default SignUpPage
