import React, { useState } from 'react';
import { Formik } from 'formik'
import { Helmet } from 'react-helmet'
import FormContainer from '../ui/formContainer'
import InputContainer from '../ui/inputContainer'
import Input from '../ui/input'
import Checkbox from '../ui/checkbox'
import SubmitButton from '../ui/submitButton'
import Form from '../ui/form'
import SuccessPrompt from '../ui/successPrompt'
import ErrorPrompt from '../ui/errorPrompt'

const initialValues = {
    entityName: "",
    emailAddress: "",
    password: "",
    termsAndConditions: false
};

const Root = () => {
    const [formState, setFormState] = useState("initial");

    const getSignUpCaptchaTokenAsync = () => {
        return new Promise((res, rej) => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6LdhyRgeAAAAAP-WgNJi3zsgVXCb0mIpkhAVM_o5', { action: 'signup' }).then(token => {
                    return res(token);
                })
            });
        })
    };

    const onSubmit = async (values, formikBag) => {
        const token = await getSignUpCaptchaTokenAsync();

        if (typeof window.gtag === 'function') {
            window.gtag('event', 'click', {
                event_category: 'form_submit',
                event_label: 'sign_up'
            });
        }

        const response = await fetch('https://api.beyondattendance.com/api/landing/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                entityName: values.entityName,
                emailAddress: values.emailAddress,
                password: values.password,
                recaptchaToken: token
            }),
        })

        if (response.ok && response.status === 200) {
            setFormState("success");
        } else {
            setFormState("error");
        }

        formikBag.resetForm();
    }

    return (
        <>
            <Helmet>
                <script src="https://www.google.com/recaptcha/api.js?render=6LdhyRgeAAAAAP-WgNJi3zsgVXCb0mIpkhAVM_o5" type="text/javascript" />
            </Helmet>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <FormContainer>
                            <InputContainer>
                                <label htmlFor="sign-up-company">Company Name <span style={{ color: 'red' }}>*</span></label>
                                <Input disabled={isSubmitting} required id="sign-up-company" type="text" value={values.entityName} onChange={event => setFieldValue("entityName", event.target.value)} />
                            </InputContainer>

                            <InputContainer>
                                <label htmlFor="sign-up-email">Email Address<span style={{ color: 'red' }}>*</span></label>
                                <Input disabled={isSubmitting} autoComplete="username" required id="sign-up-email" type="email" value={values.emailAddress} onChange={event => setFieldValue("emailAddress", event.target.value)} />
                            </InputContainer>

                            <InputContainer>
                                <label htmlFor="sign-up-password">Password<span style={{ color: 'red' }}>*</span></label>
                                <Input disabled={isSubmitting} autoComplete="new-password" required id="sign-up-password" type="password" minLength="8" value={values.password} onChange={event => setFieldValue("password", event.target.value)} />
                            </InputContainer>

                            <InputContainer>
                                <label htmlFor="sign-up-ts-cs">
                                    <Checkbox disabled={isSubmitting} required id="sign-up-ts-cs" type="checkbox" checked={values.termsAndConditions} onChange={() => setFieldValue("termsAndConditions", !values.termsAndConditions)} />
                                    <span>I have read and accept the <a href="/terms-and-conditions" target="_blank" rel="noreferrer">Terms and Conditions</a> and <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a><span style={{ color: 'red' }}>*</span></span>
                                </label>
                            </InputContainer>
                        </FormContainer>
                        {formState === "success" && <SuccessPrompt><span role="img" aria-label="Success">✅</span> Thank you for signing up. Please check your email inbox.</SuccessPrompt>}
                        {formState === "error" && <ErrorPrompt><span role="img" aria-label="Error">❌</span> An error occurred while signing up. Please try again.</ErrorPrompt>}
                        <SubmitButton disabled={isSubmitting} type="submit">Sign Up</SubmitButton>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default Root;
